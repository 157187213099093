import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../main/routes';
import DemoBpRiskReport from './bp/DemoBpRiskReport';
import DemoCadRiskReport from './cad/DemoCadRiskReport';
import DemoGxgCadRiskReport from './cad/DemoGxgCadRiskReport';
import DemoT2dRiskReport from './t2d/DemoT2dRiskReport';
import DemoT2dTreatmentReport from './t2d/DemoT2dTreatmentReport';
import NotFoundPage from '../main/NotFoundPage';
import DemoCkdRiskReport from './ckd/DemoCkdRiskReport';
import DemoStrokeRiskReport from './stroke/DemoStrokeRiskReport';
import DemoColorectalRiskReport from './colorectal/DemoColorectalRiskReport';
import DemoAtrialRiskReport from './atrial/DemoAtrialRiskReport';
import DemoBpTreatmentReport from './bp/DemoBpTreatmentReport';
import DemoBreastCancerRiskReport from './breast/DemoBreastCancerRiskReport';
import DemoProstateCancerRiskReport from './prostate/DemoProstateCancerRiskReport';
import DemoMelanomaCancerRiskReport from './melanoma/DemoMelanomaCancerRiskReport';
import DemoHypercholesterolRiskReport from './hypercholesterol/DemoHypercholesterolRiskReport';
import DemoLongTermFollowUp from './DemoLongTermFollowUp';
import DemoLabPatients from './DemoLabPatients';
import DemoOsteoporosisRiskReport from "./osteoporosis/DemoOsteoporosisRiskReport";
import DemoCadRiskReportEx from "./cad/DemoCadRiskReportEx";
import DemoPatientRisks from "./DemoPatientRisks";

export default function OpenDemoPages() {
  return (
    <Routes>
      {/* demo */}
      <Route path={routes.PATIENT_LIST} element={<DemoLabPatients />} />
      <Route
        path={routes.DEMO_PATIENT_FOLLOWUP}
        element={<DemoLongTermFollowUp />}
      />
      <Route path={routes.PATIENT_RISKS} element={<DemoPatientRisks />} />
      <Route path={routes.PATIENT_BP} element={<DemoBpRiskReport />} />
      <Route
        path={routes.DEMO_PATIENT_BP_TREAT}
        element={<DemoBpTreatmentReport />}
      />
      <Route path={routes.GXG_PATIENT_CAD} element={<DemoGxgCadRiskReport />} />
      <Route path={routes.EX_PATIENT_CAD} element={<DemoCadRiskReportEx />} />
      <Route path={routes.PATIENT_CAD} element={<DemoCadRiskReport />} />
      <Route path={routes.PATIENT_T2D} element={<DemoT2dRiskReport />} />
      <Route
        path={routes.DEMO_PATIENT_T2D_TREAT}
        element={<DemoT2dTreatmentReport />}
      />
      <Route path={routes.DEMO_PATIENT_CKD} element={<DemoCkdRiskReport />} />
      <Route
        path={routes.PATIENT_CHOLESTEROL}
        element={<DemoHypercholesterolRiskReport />}
      />
      <Route
        path={routes.DEMO_PATIENT_STROKE}
        element={<DemoStrokeRiskReport />}
      />
      <Route
        path={routes.DEMO_PATIENT_ATRIAL_FIBRILLATION}
        element={<DemoAtrialRiskReport />}
      />
      <Route
        path={routes.DEMO_PATIENT_COLORECTAL}
        element={<DemoColorectalRiskReport />}
      />
      <Route
        path={routes.DEMO_PATIENT_BREAST}
        element={<DemoBreastCancerRiskReport />}
      />
      <Route
        path={routes.DEMO_PATIENT_PROSTATE}
        element={<DemoProstateCancerRiskReport />}
      />
      <Route
        path={routes.DEMO_PATIENT_MELANOMA}
        element={<DemoMelanomaCancerRiskReport />}
      />
      <Route
        path={routes.DEMO_PATIENT_OSTEOPOROSIS}
        element={<DemoOsteoporosisRiskReport />}
      />
      {/*<Route path={`${routes.MOBILE}/*`} element={<MobileOpenDemoPages />} />*/}
      <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage />} />
    </Routes>
  );
}
