// @flow
import React from 'react';
import IdentifiedPatientAttributes from "../common/reports/IdentifiedPatientAttributes";

type Props = {
  onCancel: any,
  onApply: any
};

export default function PilotLabAddPatient(props: Props) {
  return (
    <IdentifiedPatientAttributes
      title="ADD NEW PATIENT"
      riskTrait={null}
      labelPrefix=""
      onCancel={props.onCancel}
      onApply={props.onApply}
      adding={true}
    />
  );
}
