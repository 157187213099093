import cadImage from "../images/heart-attack.png";
import {TestType} from "../utils/test_types";
import cholesterolImage from "../images/cholesterol.png";
import htnGaugeImage from "../images/blood-pressure-gauge-light.png";
import t2dImage from "../images/sugar-blood-level.png";
import colorectalImage from "../images/colorectal-cancer.png";
import prostateImage from "../images/prostate-cancer.png";
import breastCancerImage from "../images/breast-cancer.png";

export const REPORT_ITEMS = {
    cardio: {
      name: "Cardiometabolic",
      childItems: {
        cad: {
          name: "Coronary artery disease",
          icon: cadImage,
          tests: TestType.CARDIOMETABOLIC_RISK
        },
        hchol: {
          name: "Hypercholesterolemia",
          icon: cholesterolImage,
          tests: TestType.CARDIOMETABOLIC_RISK
        },
        htn: {
          name: "Hypertension",
          icon: htnGaugeImage,
          tests: TestType.CARDIOMETABOLIC_RISK
        },
        t2d: {
          name: "Type 2 diabetes",
          icon: t2dImage,
          tests: TestType.CARDIOMETABOLIC_RISK
        }
      }
    },
    cancer: {
      name: "Cancer",
      childItems: {
        colorectal: {
          name: "Colorectal",
          icon: colorectalImage,
          tests: TestType.CANCER_RISK
        },
        prostate: {
          name: "Prostate",
          icon: prostateImage,
          tests: TestType.CANCER_RISK
        },
        breast: {
          name: "Breast",
          icon: breastCancerImage,
          tests: TestType.CANCER_RISK
        }
      }
    }
  }