// @flow
import React, {useCallback, useEffect} from 'react';
import { Box } from '@chakra-ui/react';
import {
	getAgeAttr, getBooleanAttr, getWeightAttr
} from "../../common/calculator/calculator-attr";
import {calcOdds, calcRiskPercentile, calcSumCoefByValue} from "../../utils/log_reg_utils";
import {getCadRiskLevel, getCadRiskColor} from "../../common/risk-colors";
import cadReportStrings from "../cad-report-common";
import {boolToOneOrZero} from "../../utils/numbers";
import {calcBmiNumeric} from "../../utils/bmi";
import CalculatorOrMobile from "../../common/calculator/CalculatorOrMobile";

type Props = {
		sex: number,
		age: ?number,
		weight: number,
		height: number,
		hypertensive: boolean,
		hypercholesterol: boolean,
		exercise: boolean,
		diabetes: boolean,
		smoker: boolean,
		// cadScore: number,
		stdizedCadScore: number,
		printing: boolean,
		callbackInitRiskPercentile: any,
		logReg: Object,
		geneticRiskPercentile: number
};

export default function ConsumerCadCalculator(props: Props) {
	const {logReg, stdizedCadScore, height} = props;
	delete logReg.col_standardization.overall_risk_cad;

	const runLogReg = useCallback((sex: number, input: Object) => {
		const bmi = calcBmiNumeric(input.weight, height / 100)
		const attrs = {
			sex: sex,
			age: input.age,
			bmi: bmi,
			t2d: boolToOneOrZero(input.diabetes),
			smoking: boolToOneOrZero(input.smoker),
			hypertensive: boolToOneOrZero(input.hypertensive),
			hypercholesterol: boolToOneOrZero(input.hypercholesterol),
			exercise: boolToOneOrZero(input.exercise),
			overall_risk_cad: stdizedCadScore
		}
		const s = calcSumCoefByValue(attrs, logReg);
		return {
			percentile: calcRiskPercentile(s, logReg.percentiles),
			// risk: calcRiskUsingSurvival(s, logReg.mean_coef_by_value, logReg.survival_rate) * 100,
			risk: null,
			odds: calcOdds(s)
		}
	}, [logReg, stdizedCadScore, height]);

	const {weight, sex, age, diabetes, smoker, hypertensive, hypercholesterol, exercise, callbackInitRiskPercentile} = props;
	useEffect(() => {
		if (callbackInitRiskPercentile) {
			const initResults = runLogReg(
				sex,
	{
					age: age,
					height: height,
					weight: weight,
					t2d: boolToOneOrZero(diabetes),
					smoking: boolToOneOrZero(smoker),
					hypertensive: boolToOneOrZero(hypertensive),
					hypercholesterol: boolToOneOrZero(hypercholesterol),
					exercise: boolToOneOrZero(exercise),
					overall_risk_cad: stdizedCadScore
				}
			);
			callbackInitRiskPercentile(initResults.percentile);
		}
	}, 	[runLogReg, height, weight, sex, age, diabetes, smoker, hypertensive, hypercholesterol, exercise, stdizedCadScore, callbackInitRiskPercentile]);

	const numericAttrs = [
		getAgeAttr(props.age),
    getWeightAttr(props.weight)
	];
	const booleanAttrs = [
		getBooleanAttr("smoker", "Smoker", props.smoker, false, true, undefined, false),
		getBooleanAttr("exercise", "Exercise physical activity", props.exercise, false, true, undefined, false),
		getBooleanAttr("hypertensive", "High blood pressure", props.hypertensive, false, true, undefined, false),
		getBooleanAttr("hypercholesterol", "High cholesterol levels", props.hypercholesterol, false, true, undefined, false),
		getBooleanAttr("diabetes", "Diabetes", props.diabetes, false, true, undefined, false)
	];
	const infoTexts = {
		relativeToPopulation: {
			header: cadReportStrings.RELATIVE_TO_POPULATION_INFO_HEADER,
			text: cadReportStrings.RELATIVE_TO_POPULATION_INFO_TEXT,
			footer: cadReportStrings.INFO_FOOTER
		},
		relativeToCurrent: {
			header: cadReportStrings.RELATIVE_TO_CURRENT_INFO_HEADER,
			text: cadReportStrings.RELATIVE_TO_CURRENT_INFO_TEXT,
			footer: cadReportStrings.INFO_FOOTER
		},
		model: cadReportStrings.MODEL_OVERALL_RISK,
		youngerParticipant: cadReportStrings.MODEL_OVERALL_RISK_YOUNGER_PARTICIPANT,
		olderParticipant: cadReportStrings.MODEL_OVERALL_RISK_OLDER_PARTICIPANT,
		agePopInfoTexts: cadReportStrings.MODEL_OVERALL_RISK_AGE_POP_INFO_TEXT
	};

	const geneticRiskQuintile = Math.floor(props.geneticRiskPercentile / 20);
	return (
		<Box>
			<CalculatorOrMobile
				sex={props.sex}
				riskQuintile={geneticRiskQuintile}
				riskPercentile={props.geneticRiskPercentile}
				numericAttrs={numericAttrs}
				booleanAttrs={booleanAttrs}
				selectionAttrs={[]}
				callbackRunLogReg={runLogReg}
				callbackInitialRisks={callbackInitRiskPercentile}
        callbackGetRecommendations={null}
				disease='coronary artery disease'
				maxRisk={null}
        showRisk={false}
				showRelativeToPop={true}
				showRelativeToCurrent={true}
				riskGauge={null}
        callbackGetColorByRisk={getCadRiskColor}
			  callbackGetRiskLevel={getCadRiskLevel}
				infoTexts={infoTexts}
				printing={props.printing}
			/>
		</Box>);
}
