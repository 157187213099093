// @flow
import React, {useCallback, useState} from 'react';

import {Box, Checkbox, Flex, ListItem, Spacer, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {ALL_TESTS, TestType, NO_TESTS, isTestPresent} from "../utils/test_types";
import ActionButton from "../common/ActionButton";
import COLORS from "../common/colors";
import {v4 as uuid4} from "uuid";
import {TestTube} from "@phosphor-icons/react";
import {phosphorImageComp} from "../common/image";

type Props = {
  patientId: string,
	patientName: string,
	currentTests: number,
  callbackOnApply: any,
	callbackOnCancel: any,
	editingEnabled: boolean
};


export default function PilotNewOrder(props: Props) {
	const {callbackOnApply, callbackOnCancel} = props;
	const [selectedOrders, setSelectedOrders] = useState(props.currentTests);
	const [canOrder, setCanOrder] = useState(false)
	const hasCardioTest = isTestPresent( TestType.CARDIOMETABOLIC_RISK, props.currentTests);
	const hasCancerTest = isTestPresent(TestType.CANCER_RISK, props.currentTests);

	const updateSelectedTest = useCallback((test: number, selected: boolean)=> {
		let updatedTests;
		if (selected) {
			updatedTests = selectedOrders | test;
		} else {
			updatedTests = selectedOrders & ~test;
		}
		setSelectedOrders(updatedTests);
		setCanOrder(updatedTests > selectedOrders);
	},[selectedOrders, setSelectedOrders]);

	const onCardioCheckChange = useCallback((e: Object)=>{
			updateSelectedTest(TestType.CARDIOMETABOLIC_RISK, e.target.checked);
		}, [updateSelectedTest]
	);
	const onCancerCheckChange = useCallback((e)=>{
			const tests = e.target.checked ? (TestType.CANCER_RISK | TestType.CARDIOMETABOLIC_RISK) : TestType.CANCER_RISK;
			updateSelectedTest(tests, e.target.checked);
		}, [updateSelectedTest]
	);

	const onOrder = useCallback(()=>{
		callbackOnApply(selectedOrders);
	}, [callbackOnApply, selectedOrders]);
	const onCancel = useCallback(()=>{
		callbackOnCancel();
	}, [callbackOnCancel]);
  return (
		<Box m={"20px"} >
			<Flex align={"center"} mb="30px">
				<Text  fontSize={18} color={COLORS.REPORT_TEXT_GRAY} fontWeight={"bold"}>TEST ORDERS</Text>
				<Spacer/>
				<Box color={COLORS.REPORT_TEXT_GRAY}>
					{phosphorImageComp(TestTube, "20px", "20px")}
				</Box>
			</Flex>
			{props.currentTests !== NO_TESTS && <Box my={"30px"} color={COLORS.REPORT_TEXT_GRAY}>
				<Text mb={"10px"}>Submitted orders:</Text>
	      <UnorderedList ml={"40px"} >
					{hasCardioTest && <ListItem key={uuid4()}>CardioRisk+</ListItem>}
					{hasCancerTest && <ListItem key={uuid4()}>CancerRisk+</ListItem>}
				</UnorderedList>
			</Box>}
			{props.editingEnabled && props.currentTests !== ALL_TESTS && <Box my={"40px"}>
				<Text my={"20px"}>Select the tests you wish to order:</Text>
				<Stack spacing={"15px"}>
					{!hasCardioTest && <Checkbox size='lg' onChange={onCardioCheckChange} >
						CardioRisk+
					</Checkbox>}
					{!hasCancerTest && (
						<Checkbox size='lg' onChange={onCancerCheckChange} isDisabled={!isTestPresent( TestType.CARDIOMETABOLIC_RISK, selectedOrders)}>
							CancerRisk+
						</Checkbox>)
					}
				</Stack>
			</Box>}
			<Flex mt={"40px"}>
					<Spacer/>
          <ActionButton name={props.editingEnabled ? "Cancel" : "Close"} onClick={onCancel} mr="20px"/>
					{props.editingEnabled && <ActionButton
            name={"Order"}
            onClick={onOrder}
            isDisabled={!canOrder}
            minW={"100px"}
          />}
					<Spacer/>
			</Flex>
		</Box>
  );
}

