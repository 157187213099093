// @flow
import React, {useCallback, useEffect} from 'react';
import {Flex, Box} from '@chakra-ui/react';

import withNavigation from '../main/WithRoutes';
import queryString from "query-string";
import DemoCardioReport from "./DemoCardioReport";
import DemoCancerReport from "./DemoCancerReport";
import DemoWomenHealthReport from "./DemoWomenHealthReport";
import DemoBoneReport from "./DemoBoneReport";
import DemoLeftBar from "./DemoLeftBar";
import {MainMenuContext} from "../context/TopBarContext";
import DemoMobileReportsMenu from "./DemoMobileReportsMenu";
import withMobile from "../common/mobile/withMobile";

type Props = {
  params: any,
  navigate: any,
  location: any,
	isMobile: boolean
};


export function DemoPatientRisksImpl(props: Props) {
	const [, setMainMenuContent] = React.useContext(MainMenuContext);
  const {isMobile} = props;
  const queryParams = queryString.parse(props.location.search);
	const getInitialGroup = useCallback(()=> {
		if (queryParams.group === "cancer") {
			return "cancer"
		}
		return "cardio"
	}, [queryParams])
  const [selectedGroup, setSelectedGroup] = React.useState(getInitialGroup());
  const [selectedTab, setSelectedTab] = React.useState(undefined);
	const onSelectedItemChange = useCallback((group, item) => {
		setSelectedGroup(group);
		setSelectedTab(item);
	}, [setSelectedGroup, setSelectedTab])
	const showCardio = selectedGroup === "cardio";
	const showCancer = selectedGroup === "cancer";
	const showWomenHealth = selectedGroup === "women";
	const showBoneDisease = selectedGroup === "bone";

	useEffect(() => {
		if (isMobile) {
			setMainMenuContent(<DemoMobileReportsMenu onSelectedItemChange={onSelectedItemChange}
																								selectedGroup={selectedGroup}/>);
		}
	}, [isMobile, onSelectedItemChange, setMainMenuContent, selectedGroup]);

	return (
		<>
			{!isMobile && (
				<Flex w="100%">
					<Box w={"10%"} minW={"200px"}>
						<DemoLeftBar onSelectedItemChange={onSelectedItemChange} selectedGroup={selectedGroup}/>
					</Box>
					<Box w={"90%"}>
						{showCardio && <DemoCardioReport selectedTabName={selectedTab}/>}
						{showCancer && <DemoCancerReport selectedTabName={selectedTab}/>}
						{showWomenHealth && <DemoWomenHealthReport selectedTabName={selectedTab}/>}
						{showBoneDisease && <DemoBoneReport />}
					</Box>
				</Flex>)}
			{isMobile && (
				<Box w="100%">
					<Box mt={"50px"}>
						{showCardio && <DemoCardioReport selectedTabName={selectedTab}/>}
						{showCancer && <DemoCancerReport selectedTabName={selectedTab}/>}
						{showWomenHealth && <DemoWomenHealthReport selectedTabName={selectedTab}/>}
						{showBoneDisease && <DemoBoneReport />}
					</Box>
				</Box>)}
			</>

	);
}

export default withNavigation(withMobile(DemoPatientRisksImpl));
