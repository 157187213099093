// @flow
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Spacer,
  Flex,
  Box, useToast, Modal, ModalOverlay, ModalContent, ModalBody, FormControl, useDisclosure
} from '@chakra-ui/react';
import DemoCadRiskReportEx from './cad/DemoCadRiskReportEx';
import DemoCadRiskReport from './cad/DemoCadRiskReport';
import DemoT2dRiskReport from './t2d/DemoT2dRiskReport';
import DemoBpRiskReport from './bp/DemoBpRiskReport';
import DemoHypercholesterolRiskReport from './hypercholesterol/DemoHypercholesterolRiskReport';
import withNavigation from '../main/WithRoutes';
import DemoLongTermFollowUp from './DemoLongTermFollowUp';
import LinkButton from '../common/LinkButton';
import routes from '../main/routes';
import COLORS from '../common/colors';
import PrintButton from '../common/PrintButon';
import {v4 as uuid4} from "uuid";
import DemoAtrialRiskReport from "./atrial/DemoAtrialRiskReport";
import ShareButton from "../common/ShareButton";
import withMobile from "../common/mobile/withMobile";
import PilotShareWithPatient from "../capilots/PilotShareWithPatient";

type Props = {
  location: any,
  navigate: any,
  selectedTabName: ?string,
  isMobile: boolean
};

function DemoCardioReport(props: Props) {
  const {isMobile} = props;
  const [printingState, setPrintingState] = React.useState({printing: false, resolve: undefined});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const onPrintBeforeGetContent = () => {
     return new Promise((resolve) => {
      console.log(`tabIndex is ${tabIndex}`)
      setPrintingState(prevPrintingState=>({printing: true, resolve}));
     })
  }
  useEffect(() => {
    const {resolve, ...otherState} = printingState;
    if (resolve) {
        resolve();
        setPrintingState({ ...otherState, resolve: undefined });
    }
  }, [printingState]);
  const onAfterPrint = () => { setPrintingState({printing: false, resolve: undefined}); }

  const reportRefCad = useRef(null);
  const reportRefT2d = useRef(null);
  const reportRefHtn = useRef(null);
  const reportRefChol = useRef(null);
  const reportRefAtrial = useRef(null);
  const reportRefFollow = useRef(null);

  const [showSimpleCad, setShowSimpleCad] = React.useState(true);

  const refByTabIndex = useMemo(()=> {
    return [
      reportRefCad,
      reportRefT2d,
      reportRefHtn,
      reportRefChol,
      reportRefAtrial,
      reportRefFollow
    ];
  }, []);

  const {selectedTabName} = props;
  const getSelectedTabIndex = useCallback(() => {
    const tabName = selectedTabName;
    let selectedTabIndex = 0;
    if (tabName === 't2d') {
      selectedTabIndex = 1;
    }
    if (tabName === 'htn') {
      selectedTabIndex = 2;
    }
    if (tabName === 'hchol') {
      selectedTabIndex = 3;
    }
    if (tabName === 'af') {
      selectedTabIndex = 4;
    }
    if (tabName === 'follow') {
      selectedTabIndex = 5;
    }
    return selectedTabIndex;
  }, [selectedTabName]);
  const [tabIndex, setTabIndex] = useState(0);
  const controlledTabIndex = getSelectedTabIndex();
  if (controlledTabIndex !== tabIndex) setTabIndex(controlledTabIndex);

  const onTabChange = useCallback(index => {
    setTabIndex(index)
  }, []);

  const toast = useToast()
  const onShare = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const onApplyShareWithPatient = useCallback(async (_email, _phone, _username) => {
    onClose();
    toast({
          description: "This is a demo patient. For a real patient, a user account would have been created and a login email would have been sent to the patient.",
          status: 'info',
          duration: 9000,
          isClosable: true
         });
    }, [onClose, toast]);

  const onSwitchToSimpleCadView = useCallback(() => {
    setShowSimpleCad(true);
  }, [setShowSimpleCad]);

  const onSwitchToAdvancedCadView = useCallback(() => {
    setShowSimpleCad(false);
  }, [setShowSimpleCad]);

  const emptySharePatient = {
    email: null,
    phone: null
  }

  return (
    <Tabs
      variant="soft-rounded"
      // my="10px"
      size="sm"
      index={tabIndex}
      onChange={onTabChange}
    >
      <Flex mx={isMobile ? 0 : "7%"}>
        <TabList align={"center"} h={0} w={"1px"}>
          <Box/>
          <Box/>
          <Box/>
          <Box/>
          <Box/>
          <Box/>
        </TabList>
        {!isMobile && <Spacer />}
        {!isMobile && <Box h="40px" mt="10px" ml="3%">
          <ShareButton
            title="Share with patient"
            onShare={onShare}
            isTextDisplay={true}
          />
        </Box>}
        {!isMobile && <LinkButton
          fontWeight="bold"
          fontSize={16}
          to={`/${routes.DEMO}/${routes.PATIENT_LIST}`}
          bg="transparent"
          color={COLORS.REPORT_TEXT}
          h="40px"
          ml="10px"
          mt="10px"
        >
          Back to patient list
        </LinkButton>}
        {!isMobile && <Box h="40px" mt="10px" ml="10px">
          <PrintButton componentToPrint={refByTabIndex[tabIndex]} callbackOnBeforeGetContent={onPrintBeforeGetContent} callbackOnAfterPrint={onAfterPrint}/>
        </Box>}
      </Flex>
      <TabPanels mt={"-50px"}>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefCad} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
              {showSimpleCad && <DemoCadRiskReport printing={printingState.printing} callbackSwitchToAdvanced={onSwitchToAdvancedCadView}/>}
              {!showSimpleCad && <DemoCadRiskReportEx printing={printingState.printing} callbackSwitchToSimple={onSwitchToSimpleCadView}/>}
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefT2d} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoT2dRiskReport printing={printingState.printing}/>
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefT2d} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoBpRiskReport ref={reportRefHtn} printing={printingState.printing}/>
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefChol} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoHypercholesterolRiskReport printing={printingState.printing}/>
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
          <Box ref={reportRefAtrial} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoAtrialRiskReport printing={printingState.printing}/>
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={0}>
            <DemoLongTermFollowUp />
        </TabPanel>
      </TabPanels>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotShareWithPatient
                patient={emptySharePatient}
                onCancel={onClose}
                onApply={onApplyShareWithPatient}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Tabs>
  );
}

export default withNavigation(withMobile(DemoCardioReport));
