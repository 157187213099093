// @flow

const routes = {
  LANDING: '/',
  APP: '/app',
  LOGIN: '/login',
  HOME: 'home',
  ABOUT: '/about',
  BP: `bp`,
  WIZARD: `wizard`,
  UPLOAD: `upload`,
  FOLLOW: `follow`,
  CHEMOTHERAPY: `chemotherapy`,
  LAB: `lab`,
  CV: `cv`,
  POPHEALTH: `population`,
  PATIENT: `patient`,
  DEMO: 'demo',
  MOBILE: 'mobile',
  BARCODE: 'bc',
  ATHENAHEALTH: 'athenahealth',
  ATHENAHEALTH_AUTH: 'athenahealth/auth',
  ATHENAHEALTH_LOGGEDOFF: 'athenahealth/loggedoff',
  PILOTS: 'pilots',
  CLINICS: 'clinics/:lab',
  LAB_PILOT: 'pilots/:lab',
  PATIENT_LIST: 'patient/list',
  PATIENT_RISKS: 'patient/risks',
  PATIENT_CARDIO: 'patient/cardio',
  PATIENT_CANCER: 'patient/cancer',
  PATIENT_BONE: 'patient/bone',
  PATIENT_WOMEN_HEALTH: 'patient/women',
  PATIENT_BP: 'patient/bp',
  GXG_PATIENT_CAD: 'gxg/patient/cad',
  EX_PATIENT_CAD: 'ex/patient/cad',
  PATIENT_CAD: 'patient/cad',
  PATIENT_T2D: 'patient/t2d',
  PATIENT_CHOLESTEROL: 'patient/cholesterol',
  CLIENT_DEMO: ':client/demo',
  DEMO_PATIENT_FOLLOWUP: 'patient/follow',
  DEMO_PATIENT_BP_TREAT: 'patient/bp/treat',
  DEMO_PATIENT_T2D_TREAT: 'patient/t2d/treat',
  DEMO_PATIENT_CKD: 'patient/ckd',
  DEMO_PATIENT_STROKE: 'patient/stroke',
  DEMO_PATIENT_ATRIAL_FIBRILLATION: 'patient/af',
  DEMO_PATIENT_COLORECTAL: 'patient/colorectal',
  DEMO_PATIENT_BREAST: 'patient/breast',
  DEMO_PATIENT_PROSTATE: 'patient/prostate',
  DEMO_PATIENT_MELANOMA: 'patient/melanoma',
  DEMO_PATIENT_OSTEOPOROSIS: 'patient/osteoporosis',
  SELF: 'self/:patient',
  SELF_ATTRIBUTES: 'attributes',
  SELF_CARDIO: 'cardio',
  SELF_BP: 'bp',
  SELF_CAD: ':cad',
  SELF_T2D: 't2d',
  SELF_RISKS: 'risks',
  SELF_CHOLESTEROL: ':patient/cholesterol',
  ROUTE_NOT_FOUND: '/*'
};

export default routes;

export function isPilotsUrl(url: string) {
  return url.includes("/pilots/");
}

export function isClinicsUrl(url: string) {
  return url.includes("/clinics/");
}

export function isAthenaHealthUrl(url: string) {
  return url.includes("/athenahealth/");
}


export function isSelfUrl(url: string) {
  return !isPilotsUrl(url) && !isClinicsUrl(url) && url.includes("/self/");
}

export function getPilotsOrClinics(url: string) {
  if (isPilotsUrl(url)) return "pilots";
  if (isClinicsUrl(url)) return "clinics";
  return null;
}