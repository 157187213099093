//flow
export default function objectContainsText(o: Object, attributes: string[], text: ?string, caseSensitive: boolean) {
	if (!text) return true;
	if (!o) return false;
	const searchText = caseSensitive ? text : text.toLowerCase();
	let ok = false;
	attributes.forEach(attr => {
		if (caseSensitive) {
			if (o[attr] && o[attr].toString().includes(searchText)) ok = true;
		} else {
			if (o[attr] && o[attr].toString().toLowerCase().includes(searchText)) ok = true;
		}
	})
	return ok;
}
