// @flow
import React, { useCallback, useState } from "react";
import {
  Text,
  Box,
  Flex,
  Spacer,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody, FormControl, useToast
} from "@chakra-ui/react";

import COLORS from "../common/colors";
import LinkButton from "../common/LinkButton";

import routes, {getPilotsOrClinics} from "../main/routes";
import getPatientAge from "../utils/age";
import cadImage from "../images/heart-attack.png";
import cancerImage from "../images/cancer_bw.png";
import withNavigation from "../main/WithRoutes";
import PilotPatientVisitUnion from "./PilotPatientVisitUnion";
import {BsShare} from "react-icons/bs";
import {isCancerTestCompleted, TestType, isTestPresent, TestTypeDisplayNames} from "../utils/test_types";
import ActionButtonImpl from "../common/ActionButton";
import addTest from "../images/add-test.png";
import PilotNewOrder from "../capilots/PilotNewOrder";
import PilotAccRisk from "./PilotAccRisk";
import {AiOutlineToTop} from "react-icons/ai";
import {addOrder} from "../api/capilots";
import PopupMessage from "../common/PopupMessage";

type Props = {
  patient: Object,
  bg: string,
  params: Object,
  location: Object,
  allowEditing: boolean,
  isIdentifiable: boolean,
  onPatientRecordChange: any,
  currentOrders: number
};

function PatientCardImpl(props: Props) {
  const disclosureOrders = useDisclosure();
  const isOpenOrders = disclosureOrders.isOpen;
  const onOpenOrders = disclosureOrders.onOpen;
  const onCloseOrders = disclosureOrders.onClose;
  const disclosureAcc = useDisclosure();
  const isOpenAcc = disclosureAcc.isOpen;
  const onOpenAcc = disclosureAcc.onOpen;
  const onCloseAcc = disclosureAcc.onClose;
  const [popupMessage, setPopupMessage] = React.useState({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT});
  const onClosePopup = useCallback(()=> setPopupMessage({show: false, msg: undefined, title: undefined, color: COLORS.REPORT_TEXT}), [setPopupMessage])

  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const toast = useToast()

  const [isShowDetails: boolean, setShowDetails] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState(props.patient.ordered_tests);

  const onClick = useCallback(() => {
    setShowDetails(!isShowDetails);
  }, [isShowDetails]);
  const age = getPatientAge(
    props.patient.birth_year,
    props.patient.birth_month
  );

  const patientId = props.patient.patient_id;
  const { params, isIdentifiable, patient } = props;
  const pathname = props.location.pathname;
  const getReportPageUrl = useCallback(
    (report) => {
      const pilotsOrClinic = getPilotsOrClinics(pathname);
      if (!pilotsOrClinic) return pathname; // stay on same page
      if (report === "cancer") {
        return `${routes.APP}/${pilotsOrClinic}/${params.lab}/patient/risks?id=${patientId}&group=cancer&tab=colorectal`;
      }
      return `${routes.APP}/${pilotsOrClinic}/${params.lab}/patient/risks?id=${patientId}&group=cardio&tab=${report}`;
    },
    [patientId, params, pathname]
  );

  const isReportReady = props.patient.status.toLowerCase() === "ready";
  const isShared =  isReportReady && props.isIdentifiable && props.patient.username;
  const hasCancerReports = isCancerTestCompleted(props.patient.completed_tests);

  const imageComp = useCallback(
    (image) => <Image h="16px" w="16px" src={image} />,
    []
  );

  const onOrderTest = useCallback(()=>{
      onOpenOrders();
      }, [onOpenOrders]
  );

  const onAccRisk = useCallback(()=>{
      onOpenAcc();
      }, [onOpenAcc]
  );

  const onCallbackCloseAcc = useCallback(order => {
    onCloseAcc();
    if (order) {
      onOpenOrders();
    }
  }, [onCloseAcc, onOpenOrders]);

  const addSingleOrder = useCallback(async (testType: number, testName: string) => {
    addOrder(patientId, testName).then(() => {
        setSelectedOrders(selectedOrders | testType);
        toast({
              description: `${TestTypeDisplayNames[testName]} test ordered for ${isIdentifiable ? patient.patient_name : 'patient'}`,
              status: 'info',
              duration: 9000,
              isClosable: true
             });
      }
    ).catch(e => {
        console.log("failed to order test")
        console.log(e);
        setPopupMessage({
          show: true,
          color: COLORS.RED_STATUS,
          msg: "Failed to order test, check that the internet connection is on and try again.",
          title: "FAILED TO ORDER TEST"
        });
      });
  }, [patientId, selectedOrders, setSelectedOrders, toast, isIdentifiable, patient]);

  const onApplyOrder = useCallback(async (selectedTests: number)=>{
    if (selectedTests > selectedOrders) {
        for (const [testName, testType] of Object.entries(TestType)) {
          // $FlowFixMe
          if (isTestPresent(testType, selectedTests) && !isTestPresent(testType, selectedOrders)) {
            // $FlowFixMe
            await addSingleOrder(testType, testName);
          }
        }
    }
    onCloseOrders();
  }, [selectedOrders, onCloseOrders, addSingleOrder]);

  const hasCancerOrder= isTestPresent(TestType.CANCER_RISK, selectedOrders);
  return (
    <Flex w="100%">
      <Box
        onClick={onClick}
        bg="transparent"
        borderColor="transparent"
        borderWidth={2}
        borderRadius="5px"
        fontSize={18}
        _hover={{
          background: props.bg,
          borderColor: "gray.400",
          borderWidth: 2,
          color: COLORS.REPORT_TEXT,
        }}
        w="100%"
      >
        <Box
          my="5px"
          w="100%"
          _hover={{
            cursor: "pointer",
          }}
        >
          <Flex align="center" minW="100%" fontSize={14}>
            <Text textAlign="left" minW={props.isIdentifiable ? "100px": "50px"} maxW="100px">
              {props.isIdentifiable ? props.patient.external_id : props.patient.patient_num}
            </Text>
            {props.isIdentifiable &&
              <Text textAlign="left" minW="160px" maxW="160px" fontWeight="bold">
                {props.patient.patient_name}
              </Text>
            }
            <Text textAlign="left" minW="150px" fontWeight={props.isIdentifiable ? "normal" : "bold"}>
              {props.patient.kit_id}
            </Text>
            <Text textAlign="left" minW="80px">
              {props.patient.sex ? "Male" : "Female"}
            </Text>
            <Text minW="60px" textAlign="center">
              {age}
            </Text>
            <Text
              ml="40px"
              minW="150px"
              textAlign="left"
              color={isReportReady ? COLORS.GREEN_STATUS : COLORS.REPORT_TEXT}
              fontWeight={isReportReady ? "bold" : "normal"}
            >
              {props.patient.status}
            </Text>
            <Flex ml="20px" w="350px">
              {isReportReady && (
                <Flex>
                  <LinkButton
                    fontWeight="normal"
                    fontSize={14}
                    to={getReportPageUrl("cad")}
                    bg="gray.100"
                    color={COLORS.REPORT_TEXT}
                    h="40px"
                    w="120px"
                    mr="20px"
                    leftIcon={imageComp(cadImage)}
                  >
                    CARDIO
                  </LinkButton>
                  {(hasCancerOrder || hasCancerReports) && <LinkButton
                    fontWeight="normal"
                    fontSize={14}
                    to={getReportPageUrl("cancer")}
                    bg="gray.100"
                    color={COLORS.REPORT_TEXT}
                    h="40px"
                    w="120px"
                    mr="20px"
                    leftIcon={imageComp(cancerImage)}
                    isDisabled={!hasCancerReports}
                  >
                    CANCER
                  </LinkButton>}
                </Flex>
              )}
              {!isReportReady && <Flex align={"center"}>
                <ActionButtonImpl
                  fontWeight="normal"
                  fontSize={14}
                  bg="gray.100"
                  color={COLORS.REPORT_TEXT}
                  h="40px"
                  w="120px"
                  leftIcon={<Box color={"black"} as={AiOutlineToTop}/>}
                  onClick={onAccRisk}
                  name={"ACC"}
                />
                <Text ml={"22px"}>reports are not available yet </Text>
              </Flex>}
            </Flex>
            <Spacer />
            {isShared && <Box as={BsShare} h="18px" w="18px" mr={"32px"} mb={0} color={COLORS.GREEN_STATUS}/>}
            <ActionButtonImpl
              name={"orders  ..." }
              fontWeight="normal"
              fontSize={14}
              bg="gray.100"
              color={COLORS.REPORT_TEXT}
              h="40px"
              w="175x"
              mx="20px"
              leftIcon={imageComp(addTest)}
              onClick={onOrderTest}
            >
            </ActionButtonImpl>
          </Flex>
        </Box>
        {isShowDetails && (
          <Box>
            <PilotPatientVisitUnion
                patientId={props.patient.patient_id}
                allowEditing={props.allowEditing}
                onPatientRecordChange={props.onPatientRecordChange}
            />
          </Box>
        )}
      </Box>
      <Spacer />
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpenOrders}
        onClose={onCloseOrders}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotNewOrder
                patientId={patientId}
                patientName={`Patient #${patientId}`}
                currentTests={selectedOrders}
                callbackOnCancel={onCloseOrders}
                callbackOnApply={onApplyOrder}
                editingEnabled={props.allowEditing}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpenAcc}
        onClose={onCloseAcc}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotAccRisk
                patient={props.patient}
                callbackOnClose={onCallbackCloseAcc}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
      <PopupMessage onClose={onClosePopup} isOpen={popupMessage.show} title={popupMessage.title} message={popupMessage.msg} parentRef={initialRef} messageColor={popupMessage.color}/>
    </Flex>
  );
}

export default withNavigation(PatientCardImpl);
