// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getPatientId } from './capilots-utils';
import BreastRecommendationSection from '../breast/BreastCancerRecommendationSection';
import BreastExplanatorySection from '../breast/BreastCancerExplanatorySection';
import breastCancerImage from '../images/breast-cancer.png';
import withNavigation from '../main/WithRoutes';
import BreastChartsSection from '../breast/BreastCancerChartsSection';
import breastReportCommon from "../breast/breast-report-common";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import PatientAttributes from "../common/reports/PatientAttributes";
import {defaultCancerCalculatorUserAttribute} from "../common/reports/report-common";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  visit: Object,
  breast: Object,
  loading: boolean,
  printing: boolean,
  patientId: ?string,
  isMobile: boolean,
  isClinicianView: boolean
};

export class PilotBreastRiskReportImpl extends React.Component<
  Props
> {
  constructor(props: Props) {
    super(props);
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
  }

  patientId: string;

  render() {
    const {isMobile} = this.props;
    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="BREAST CANCER RISK REPORT"
          configTrait="breast"
          loading={this.props.loading}
          titleImage={breastCancerImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          geneticRiskInfoText={[
            breastReportCommon.GENETIC_RISK_BREAST_INFO_TEXT
          ]}
          riskPercentile={
            this.props.breast ? this.props.breast.breast_cancer.percentile : null
          }
          patientAttributesSection={this.props.printing && this.props.breast &&
            <Box w={"420px"} borderWidth={1} borderColor="gray.100">
              <PatientAttributes
                  gender={this.props.visit.sex ? "Male": "Female"}
                  age={this.props.visit.age}
                  riskTrait="Breast Cancer"
                  riskLevel={getRiskLevelByPercentile(this.props.breast.breast_cancer.percentile + 1)}
                  orderLabs={false}
                  labelPrefix="Current "
                  showBorder={false}
                  showAttributeFlags={defaultCancerCalculatorUserAttribute}
                  spacing={"7px"}
                />
            </Box>
          }
          chartsSection={
            this.props.breast ?
              <BreastChartsSection
                percentile={this.props.breast.breast_cancer.percentile}
                quintile={Math.floor(
                  this.props.breast.breast_cancer.percentile / 20
                )}
                deciles={this.props.breast.breast_cancer.deciles}
                oddsRatios={this.props.breast.breast_cancer.odds_ratio_list}
                isMobile={isMobile}
                printing={this.props.printing}
              /> : null
          }
          recommendationSection={
            !this.props.isClinicianView && <BreastRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<BreastExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(PilotBreastRiskReportImpl));
