// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import BreastCancerExplanatorySection from '../../breast/BreastCancerExplanatorySection';
import BreastCancerRecommendationSection from '../../breast/BreastCancerRecommendationSection';
import breastCancerImage from '../../images/breast-cancer.png';
import withNavigation from '../../main/WithRoutes';
import BreastChartsSection from '../../breast/BreastCancerChartsSection';
import breastReportCommon from "../../breast/breast-report-common";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};
type State = {};

export class DemoBreastCancerRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const breasts = DemoBreastCancerRiskReportImpl.loadDemoData();
    const key = `breast${this.patientId}`;
    this.breast = breasts[key] === undefined ? breasts.breast1 : breasts[key];
  }

  breast: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="BREAST CANCER RISK REPORT"
          configTrait="breast"
          titleImage={breastCancerImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            breastReportCommon.GENETIC_RISK_BREAST_INFO_TEXT
          ]}
          riskPercentile={this.breast.breast_cancer.percentile}
          chartsSection={
            <BreastChartsSection
              percentile={this.breast.breast_cancer.percentile}
              quintile={Math.floor(this.breast.breast_cancer.percentile / 20)}
              deciles={this.breast.breast_cancer.deciles}
              oddsRatios={this.breast.breast_cancer.odds_list}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <BreastCancerRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={
            <BreastCancerExplanatorySection isMobile={isMobile} />
          }
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoBreastCancerRiskReportImpl));
