// @flow
import React, {useCallback, useMemo, useState} from 'react';
import {Stack, Text, Flex, Box, Divider, Spacer} from '@chakra-ui/react';
import { IoSpeedometerOutline } from 'react-icons/io5';

import { v4 as uuid4 } from 'uuid';
import ActionButton from '../common/ActionButton';
import COLORS from '../common/colors';
import withNavigation from '../main/WithRoutes';
import DemoPatientCard from './DemoPilotPatientCard';
import {TestType, NO_TESTS} from "../utils/test_types";
import SearchTextBar from "../common/SearchTextBar";
import objectContainsText from "../utils/search";
import DemoClinicalData1 from './data/clinical1.json';
import DemoClinicalData2 from './data/clinical2.json';
import DemoClinicalData3 from './data/clinical3.json';
import DemoClinicalData4 from './data/clinical4.json';
import DemoClinicalData5 from './data/clinical5.json';

function HeaderRow() {
  return (
    <Flex
      fontWeight="bold"
      align="center"
      color="gray.400"
      fontSize={12}
      justify="left"
    >
      <Text minW="50px" textAlign="left">
        #
      </Text>
      <Text minW="150px" textAlign="left">
        KIT
      </Text>
      <Text minW="100px" textAlign="left">
        GENDER
      </Text>
      <Text minW="100px" textAlign="center">
        AGE
      </Text>
      <Text minW="150px" textAlign="left" ml="20px">
        GENETIC TEST STATUS
      </Text>
      <Flex align="center" ml="40px" w={"300px"}>
        <Box as={IoSpeedometerOutline} mr="10px" h="20px" w="20px" />
        <Text textAlign="left" mr="20px">
          RISK REPORTS
        </Text>
      </Flex>
      <Text minW="150px" textAlign="left" mr="20px">
        SHARED
      </Text>
    </Flex>
  );
}

export function PilotLabPatientListImpl() {
  const [searchText, setSearchText] = useState(undefined);
  const [searchCaseSensitive, setSearchCaseSensitive] = useState(false);
  const patients = useMemo(()=> {
    let clinicals = [
      DemoClinicalData1, DemoClinicalData2, DemoClinicalData3, DemoClinicalData4, DemoClinicalData5
    ]
    const patients = [];
    for (let i = 0; i < 5; i += 1) {
      const clinical = clinicals[i];
      patients.push({
        "age": clinical.age,
        "birth_month": 1,
        "birth_year": new Date().getFullYear() - clinical.age,
        "clinic": "clinic1",
        "doctor": null,
        "height": 180,
        "kit_id": `OD00${i + 1}`,
        "patient_id": `${i + 1}`,
        "patient_num": i + 1,
        "sex": clinical.sex,
        "gender": clinical.sex ? "Male": "Female",
        "status": i === 3 ? 'NO_ORDER' : 'READY',
        "visits": [
          {
            "a1c": clinical.a1c,
            "bmi": clinical.bmi,
            "bp_treated": clinical.bp_treated,
            "dbp": clinical.dbp,
            "diabetes": clinical.t2d,
            "fh_diabetes": clinical.diabetes_family_history,
            "hdl": clinical.hdl,
            "ldl": clinical.ldl,
            "patient_id": `${i + 1}`,
            "sbp": clinical.sbp,
            "smoker": clinical.smoking,
            "tc": clinical.tc,
            "visit_date": "2022-07-05 07:02",
            "weight": 81
          }
        ]
      })
    }
    return patients;
  }, []);
  const onSearchTextChange = useCallback( (text: string, isCaseSensitive: boolean, _showDemoPatients: boolean) => {
    setSearchCaseSensitive(isCaseSensitive)
    setSearchText(text);
  }, []);

  const patientContainsText = useCallback((patient: Object, text: ?string, caseSensitive: boolean) => {
    return objectContainsText(patient, [
        "patient_num",
        "patient_name",
        "kit_id",
        "external_id",
        "gender",
        "status",
        "age"
    ], text, caseSensitive);
  }, []);
  const getFilteredPatients = useCallback(() => {
    const filteredPatients = [];
    patients.forEach(patient => {
      if (patientContainsText(patient, searchText, searchCaseSensitive)) {
        filteredPatients.push(patient);
      }
    });
    return filteredPatients;
  }, [searchText, searchCaseSensitive, patientContainsText, patients]);
  const filteredPatients = getFilteredPatients();
  return (
    <Box mx="8%" color={COLORS.REPORT_TEXT}>
      <Flex my="30px" mr={"20px"} align={"center"}>
        <Text fontWeight={"bold"}>Demo Clinic</Text>
        <Spacer/>
        <ActionButton
          name="New patient..."
          // onClick={onOpen}
          color={COLORS.LABEL_TEXT_COLOR}
          disabled
        />
      </Flex>
      <Box mb={"30px"}>
        <SearchTextBar label={"Filter:"} onSearch={onSearchTextChange} demoFilter={false}/>
      </Box>
      <Stack fontSize={16} color={COLORS.LABEL_TEXT_COLOR} spacing="5px">
        <HeaderRow />
        <Divider color="gray.700" mt="2px" />
        {!!filteredPatients &&
          filteredPatients.map((patient, i) => (
            <Box key={uuid4()}>
              <DemoPatientCard patient={patient} bg="gray.50" currentOrders={i === 0 ? TestType.CARDIOMETABOLIC_RISK : NO_TESTS}/>
              <Divider color="gray.700" mt="4px" />
            </Box>
          ))}
      </Stack>
    </Box>
  );
}

export default withNavigation(PilotLabPatientListImpl);
