// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getPatientId } from './capilots-utils';
import ColorectalRecommendationSection from '../colorectal/ColorectalRecommendationSection';
import ColorectalExplanatorySection from '../colorectal/ColorectalExplanatorySection';
import colorectalImage from '../images/colorectal-cancer.png';
import withNavigation from '../main/WithRoutes';
import ColorectalChartsSection from '../colorectal/ColorectalChartsSection';
import colorectalReportCommon from "../colorectal/colorectal-report-common";
import {getRiskLevelByPercentile} from "../common/risk-colors";
import PatientAttributes from "../common/reports/PatientAttributes";
import {defaultCancerCalculatorUserAttribute} from "../common/reports/report-common";
import GeneticReportOrMobile from "../common/reports/GeneticReportOrMobile";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  visit: Object,
  printing: boolean,
  patientId: ?string,
  colorectal: Object,
  loading: boolean,
  isMobile: boolean,
  isClinicianView: boolean
};

export class PilotColorectalRiskReportImpl extends React.Component<
  Props
> {
  constructor(props: Props) {
    super(props);
    this.patientId = props.patientId ?? getPatientId(props.params, props.location);
  }

  patientId: string;

  render() {
    const {isMobile} = this.props;

    const printingPatientAttr = this.props.printing && this.props.colorectal &&
      <Box w={"420px"} borderWidth={1} borderColor="gray.100">
        <PatientAttributes
            gender={this.props.visit.sex ? "Male": "Female"}
            age={this.props.visit.age}
            riskTrait="Colorectal Cancer"
            riskLevel={getRiskLevelByPercentile(this.props.colorectal.colorectal_cancer.percentile + 1)}
            orderLabs={false}
            labelPrefix="Current "
            showBorder={false}
            showAttributeFlags={defaultCancerCalculatorUserAttribute}
            spacing={"7px"}
          >
          </PatientAttributes>
        </Box>;

    return (
      <Box sx={{ '@media print': { '@page': { size: 'landscape;' } } }}>
        <GeneticReportOrMobile
          isDemo={false}
          reportTitle="COLORECTAL CANCER RISK REPORT"
          configTrait="colorectal"
          loading={this.props.loading}
          titleImage={colorectalImage}
          patientNum={this.props.visit ? this.props.visit.patient_num : null}
          patientName={this.props.visit ? this.props.visit.patient_name : null}
          geneticKitId={this.props.visit ? this.props.visit.kit_id : ''}
          printing={this.props.printing}
          geneticRiskInfoText={[
            colorectalReportCommon.GENETIC_RISK_COLORECTAL_INFO_TEXT
          ]}
          riskPercentile={
            this.props.colorectal ? this.props.colorectal.colorectal_cancer.percentile : null
          }
          patientAttributesSection={printingPatientAttr}
          
          chartsSection={
            this.props.colorectal ?
              <ColorectalChartsSection
                percentile={this.props.colorectal.colorectal_cancer.percentile}
                quintile={Math.floor(
                  this.props.colorectal.colorectal_cancer.percentile / 20
                )}
                deciles={this.props.colorectal.colorectal_cancer.deciles}
                oddsRatios={this.props.colorectal.colorectal_cancer.odds_ratio_list}
                isMobile={isMobile}
                printing={this.props.printing}
              /> : null
          }
          recommendationSection={
            !this.props.isClinicianView && <ColorectalRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<ColorectalExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(PilotColorectalRiskReportImpl));
