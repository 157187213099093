// @flow
import AWS from 'aws-sdk';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import apigClientFactory from 'aws-api-gateway-client';
import COGNITO_CONFIG from '../config/auth_config';
import { ENV } from '../config/env_config';
import {setClockSkew} from "./clock";

export function getCurrentUser() {
  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_CONFIG[ENV].USERPOOL_ID,
    ClientId: COGNITO_CONFIG[ENV].APPCLIENT_ID
  });

  return userPool.getCurrentUser();
}

/* eslint consistent-return: "off" */
export function getUserSession(cognitoUser: typeof CognitoUser): Promise<any> {
  return new Promise((resolve, reject) => {
    const currentUser = cognitoUser ?? getCurrentUser();
    if (cognitoUser) console.log(`getUserSession for ${cognitoUser.username}`)
    else console.log(`getUserSession`);
    if (currentUser === null) {
      return reject({ message: 'user not logged in' });
    }
    currentUser.getSession((err, data) => {
      if (err) {
        return reject(err);
      }
      return resolve({
        email: currentUser.username,
        idToken: data.getIdToken().getJwtToken(),
        accessToken: data.getAccessToken().getJwtToken(),
        currentUser
      });
    });
  });
}

export function clearUser() {
  if (AWS.config.credentials) {
    AWS.config.credentials.clearCachedId();
  }
  AWS.config.credentials = null;
}

const cognitoPoolConfig = {
  IdentityPoolId: COGNITO_CONFIG[ENV].IDENTITYPOOL_ID
};

function initCredentials(idToken: string) {
  const credentialsConfig = {
    ...cognitoPoolConfig,
    Logins: {
      [`cognito-idp.${COGNITO_CONFIG[ENV].REGION}.amazonaws.com/${COGNITO_CONFIG[ENV].USERPOOL_ID}`]: idToken
    }
  };
  const credentials = new AWS.CognitoIdentityCredentials(credentialsConfig);
  AWS.config.update({
    region: COGNITO_CONFIG[ENV].REGION
  });
  return credentials;
}

export function updateCredentials(cognitoUser: typeof CognitoUser): Promise<any> {
  return new Promise((resolve, reject) => {
    // if credentials is null or expired
    let { credentials } = AWS.config;
    if (!credentials || credentials.needsRefresh()) {
      getUserSession(cognitoUser)
        .then(result => {
          const { idToken } = result;
          credentials = initCredentials(idToken);
          credentials.get(err => {
            if (err) {
              clearUser();
              return reject(err);
            }
            AWS.config.credentials = credentials;
            // get the clock offset from the local storage
            const systemClockOffset = parseInt(localStorage.getItem("systemClockOffset"));
            if (!systemClockOffset) {
              // if system clock offset is null or 0 then get the offset and store it
              setClockSkew().then().catch();
              console.log(AWS.config.systemClockOffset);
            }
            else {
              AWS.config.systemClockOffset = parseInt(systemClockOffset);
              AWS.config.correctClockSkew = true;
            }
            resolve(credentials);
          });
        })
        .catch(err => {
          reject(err);
        });
    } else {
      resolve(credentials);
    }
  });
}

export function makeApiGatewayCall(call: Function, invokeUrl: string, cognitoUser: typeof CognitoUser) {
  updateCredentials(cognitoUser)
    .then(credentials => {
      const apigClient = apigClientFactory.newClient({
        accessKey: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: COGNITO_CONFIG[ENV].REGION,
        systemClockOffset: AWS.config.systemClockOffset,
        retries: 1,
        invokeUrl
      });
      console.log(`call apig: ${AWS.config.systemClockOffset}`);
      call(apigClient);
    })
    .catch();
}
