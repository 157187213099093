// @flow
export const NO_TESTS = 0;

export const TestType = {
	CARDIOMETABOLIC_RISK: 1,
	CANCER_RISK: 2
}

export const TestTypeDisplayNames = {
	CARDIOMETABOLIC_RISK: "CardioRisk+",
	CANCER_RISK: "CancerRisk+"
}

export const ALL_TESTS = (TestType.CARDIOMETABOLIC_RISK | TestType.CANCER_RISK);

export function isTestPresent(test_type: number, tests: number): boolean {
	// eslint-disable-next-line no-bitwise
  return (test_type & tests) === test_type;
}


export function isCancerTestCompleted(completed_tests: number): boolean {
	return isTestPresent(TestType.CANCER_RISK, completed_tests)
}


export function isCardiometabolicTestCompleted(completed_tests: number): boolean {
	return isTestPresent(TestType.CARDIOMETABOLIC_RISK, completed_tests)
}
