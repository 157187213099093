import React, {useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Box } from '@chakra-ui/react';
import withNavigation from './WithRoutes';
import routes from './routes';
import NotFoundPage from './NotFoundPage';
import Home from '../home/Home';
import Login from './Login';
import MobileTopBar from "../top-bar/MobileTopBar";
import TopBar from "../top-bar/TopBar";
import About from '../top-bar/About';
import App from "./App";
import OpenDemoPages from '../demo/OpenDemoPages';
import version from '../version.json';
import MobileScanKitId from "../common/barcode/MobileScanKitID";
import AthenaPreLogin from "../athena/AthenaPreLogin";
import AthenaAuth from "../athena/AthenaAuth";
import RequireAuth from "../auth/RequireAuth";
import AthenaLoggedOff from "../athena/AthenaLoggedOff";
import {MainMenuContext} from "../context/TopBarContext";
import withMobile from "../common/mobile/withMobile";

type Props = {
  location: any,
  isMobile: boolean
};

export function MainImpl(props: Props) {

  const {isMobile} = props;
  const isAthenaHealth = props.location.pathname.includes("athenahealth");
  const isInIframe = window.location !== window.parent.location;
  const [mainMenuContent, setMainMenuContent] = useState(null)

  return (
    <Box>
      <MainMenuContext.Provider value={[mainMenuContent, setMainMenuContent]}>
        <ToastContainer />
        {!isAthenaHealth && isMobile && <MobileTopBar mainMenuContent={mainMenuContent}/>}
        {!isMobile && <TopBar />}
        <Routes>
          <Route exact path={routes.LANDING} element={<Home />} />
          <Route exact path={routes.ABOUT} element={<About version={version.frontend} />} />
          <Route exact path={`${routes.LOGIN}`} element={<Login />} />
          <Route path={`${routes.APP}/*`} element={
            <RequireAuth>
              <App />
            </RequireAuth>}
          />
          {isInIframe && <Route path={`${routes.ATHENAHEALTH_AUTH}`} element={<AthenaAuth />} />}
          {isInIframe && <Route path={`${routes.ATHENAHEALTH_LOGGEDOFF}`} element={<AthenaLoggedOff />} />}
          {isInIframe && <Route path={routes.ATHENAHEALTH} element={<AthenaPreLogin />} />}
          {/*for development*/}
          {/*<Route exact path={`${routes.ATHENAHEALTH_AUTH}/debug`} element={*/}
          {/*  <AthenaAuth*/}
          {/*      debugMode={true}*/}
          {/*      debugLoggedIn={true}*/}
          {/*      debugPatientId={"6f40e7de-67cf-4968-a7c8-daf6bc1a7aaf"}*/}
          {/*      // debugPatientId={null}*/}
          {/*      debugPatientResultsReady={true}*/}
          {/*  />}*/}
          {/*/>*/}
          {/*{<Route exact path={`${routes.ATHENAHEALTH}/debug`} element={<AthenaPreLogin />} />}*/}
          {/*<Route exact path={`${routes.ATHENAHEALTH}/debug/loggedoff`} element={<AthenaLoggedOff />} />*/}
          <Route path={`${routes.DEMO}/*`} element={<OpenDemoPages />} />
          <Route path={`${routes.BARCODE}/*`} element={<MobileScanKitId />} />
          {/* not found */}
          <Route path={routes.ROUTE_NOT_FOUND} element={<NotFoundPage />} />
        </Routes>
      </MainMenuContext.Provider>
    </Box>
  );
}

export default withNavigation(withMobile(MainImpl));
