// @flow
import React, {useCallback, useState} from 'react';
import {Box, Checkbox, Divider, Flex, ListItem, Spacer, Text, UnorderedList} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';
import {consentStrings} from './consent-texts'
import COLORS from "../common/colors";
import ActionButton from "../common/ActionButton";
import withMobile from "../common/mobile/withMobile";

type Props = {
  onConsent: any,
  isMobile: boolean
};

function ConsentImpl(props: Props) {
  const {onConsent} = props;
  const [consentAgreed, setConsentAgreed] = useState(false);
  const onConsentCheckChange = useCallback((e)=> {
        setConsentAgreed(e.target.checked);
      }, [setConsentAgreed]
  )
  const onContinue = useCallback(()=>onConsent(), [onConsent]);
  const {isMobile} = props;
  return (
    <Box mr={"10%"} color={COLORS.REPORT_TEXT_GRAY} fontSize={isMobile ? 13 : 16}>
      <Text>{consentStrings.CONSENT1}</Text>
      <Text mt={isMobile ? "10px" : "30px"}>{'This includes:'}</Text>
      <UnorderedList ml={isMobile ? "20px" : "40px"} mt={"10px"}>
        {consentStrings.INFORMATION_ITEMS.map(text => (
          <ListItem key={uuid4()}>{text}</ListItem>
        ))}
      </UnorderedList>
      <Text mt={isMobile ? "10px" : "30px"}>{consentStrings.DEINDETIFIED_MEANING}</Text>
      <Text mt={"20px"}>DISCLAIMERS</Text>
      <UnorderedList ml={isMobile ? "20px" : "40px"} mt={"10px"}>
        {consentStrings.DISCLAIMERS.map(text => (
          <ListItem fontSize={isMobile ? 12 : 16} key={uuid4()}>{text}</ListItem>
        ))}
      </UnorderedList>
      <Divider mt={isMobile ? "10px" : "20px"}/>
      <Checkbox
        color={COLORS.REPORT_TEXT}
        ml={isMobile ? "10px" : "20px"}
        size={"sm"}
        onChange={onConsentCheckChange}
        mt={isMobile ? "25px" : "30px"}
      >
        I understand and agree to proceed
      </Checkbox>
      <Flex mt={isMobile ? "25px" : "30px"}>
        <ActionButton
          color={COLORS.REPORT_TEXT}
          name="Continue..."
          onClick={onContinue}
          isDisabled={!consentAgreed}
        />
        <Spacer />
      </Flex>
    </Box>
  );
}

export default withMobile(ConsentImpl);
