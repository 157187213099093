// @flow
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Spacer,
  Flex,
  Box, useToast, Modal, ModalOverlay, ModalContent, ModalBody, FormControl, useDisclosure
} from '@chakra-ui/react';
import DemoColorectalRiskReport from './colorectal/DemoColorectalRiskReport';
import DemoBreastCancerRiskReport from './breast/DemoBreastCancerRiskReport';
import DemoProstateCancerRiskReport from './prostate/DemoProstateCancerRiskReport';
import DemoMelanomaCancerRiskReport from './melanoma/DemoMelanomaCancerRiskReport';
import withNavigation from '../main/WithRoutes';
import LinkButton from '../common/LinkButton';
import routes from '../main/routes';
import COLORS from '../common/colors';
import PrintButton from '../common/PrintButon';
import {v4 as uuid4} from "uuid";
import ShareButton from "../common/ShareButton";
import withMobile from "../common/mobile/withMobile";
import PilotShareWithPatient from "../capilots/PilotShareWithPatient";

type Props = {
  location: any,
  navigate: any,
	selectedTabName: ?string,
  isMobile: boolean
};

function DemoCancerReport(props: Props) {
  const {isMobile} = props;
  const {selectedTabName} = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const getSelectedTab = useCallback(() => {
    let selectedTab = 0;
    if (selectedTabName === 'breast') {
      selectedTab = 1;
    }
    if (selectedTabName === 'prostate') {
      selectedTab = 2;
    }
    if (selectedTabName === 'melanoma') {
      selectedTab = 3;
    }
    return selectedTab;
  }, [selectedTabName]);

  const [printingState, setPrintingState] = React.useState({printing: false, resolve: undefined});
  const onPrintBeforeGetContent = () => {
     return new Promise((resolve) => {
      setPrintingState(prevPrintingState=>({printing: true, resolve}));
     })
  }
  useEffect(() => {
    const {resolve, ...otherState} = printingState;
    if (resolve) {
        resolve();
        setPrintingState({ ...otherState, resolve: undefined });
    }
  }, [printingState]);
  const onAfterPrint = () => { setPrintingState({printing: false, resolve: undefined}); }

  const [tabIndex, setTabIndex] = useState(0);
  const controlledTabIndex = getSelectedTab();
  if (controlledTabIndex !== tabIndex) setTabIndex(controlledTabIndex);

  const onTabChange = useCallback(index => setTabIndex(index), []);
  const reportRefColorectal = useRef(null);
  const reportRefBreast = useRef(null);
  const reportRefProstate = useRef(null);
  const reportRefMelanoma = useRef(null);


  const refByTabIndex = [
    reportRefColorectal,
    reportRefBreast,
    reportRefProstate,
    reportRefMelanoma
  ];

  const toast = useToast()
  const onShare = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const onApplyShareWithPatient = useCallback(async (_email, _phone, _username) => {
    onClose();
    toast({
          description: "This is a demo patient. For a real patient, a user account would have been created and a login email would have been sent to the patient.",
          status: 'info',
          duration: 9000,
          isClosable: true
         });
    }, [onClose, toast]);

  const emptySharePatient = {
    email: null,
    phone: null
  }

  return (
    <Tabs
      variant="soft-rounded"
      size="sm"
      index={tabIndex}
      onChange={onTabChange}
    >
      <Flex mx="7%">
        <TabList align={"center"} h={0}>
          <Box/>
          <Box/>
          <Box/>
          <Box/>
        </TabList>
        <Spacer />
        {!isMobile && <Box h="40px" mt="10px" ml="3%">
          <ShareButton
            title="Share with patient"
            onShare={onShare}
            isTextDisplay={true}
          />
        </Box>}
        {!isMobile && <LinkButton
          fontWeight="bold"
          fontSize={16}
          to={`/${routes.DEMO}/${routes.PATIENT_LIST}`}
          bg="transparent"
          color={COLORS.REPORT_TEXT}
          h="40px"
          ml="10px"
          mt="10px"
        >
          Back to patient list
        </LinkButton>}
        {!isMobile && <Box h="40px" mt="10px" ml="10px">
          <PrintButton componentToPrint={refByTabIndex[tabIndex]} callbackOnBeforeGetContent={onPrintBeforeGetContent} callbackOnAfterPrint={onAfterPrint}/>
        </Box>}
      </Flex>
      <TabPanels mt={"-50px"}>
        <TabPanel key={uuid4()} px={isMobile ? 0 : 16}>
          <Box ref={reportRefColorectal} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoColorectalRiskReport printing={printingState.printing}/>
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={isMobile ? 0 : 16}>
          <Box ref={reportRefBreast} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoBreastCancerRiskReport printing={printingState.printing}/>
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={isMobile ? 0 : 16}>
          <Box ref={reportRefProstate} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoProstateCancerRiskReport printing={printingState.printing}/>
          </Box>
        </TabPanel>
        <TabPanel key={uuid4()} px={isMobile ? 0 : 16}>
          <Box ref={reportRefMelanoma} sx={{'@media print': { '@page': { size: 'A4 landscape !important;' }}}}>
            <DemoMelanomaCancerRiskReport printing={printingState.printing}/>
          </Box>
        </TabPanel>
      </TabPanels>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent color={COLORS.REPORT_TEXT}>
          <ModalBody color={COLORS.REPORT_TEXT}>
            <FormControl>
              <PilotShareWithPatient
                patient={emptySharePatient}
                onCancel={onClose}
                onApply={onApplyShareWithPatient}
              />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Tabs>
  );
}

export default withNavigation(withMobile(DemoCancerReport));
