// @flow
import {API_URLS, SERVICES} from "../config/url_config";
import requests from "../utils/request";
import {getPostAuthUrl} from "../athena/athena";

export function athenaEmbeddedAuth(iss: string, launch: string): Promise<*> {
	const postTokenUrl = getPostAuthUrl();
  const path = `${API_URLS.ATHENAAPI.EMBEDDED_AUTH}`;
  const body = {
		iss: iss,
		launch: launch,
		postAuthUrl: postTokenUrl
	}
  return requests.post(SERVICES.ATHENAAPI, {}, path, {}, body);
}

export function athenaEmbeddedToken(code: string): Promise<*> {
	const postTokenUrl = getPostAuthUrl();
  const path = `${API_URLS.ATHENAAPI.EMBEDDED_TOKEN}`;
  const body = {
		code: code,
		postTokenUrl: postTokenUrl
	}
  return requests.post(SERVICES.ATHENAAPI, {}, path, {}, body);
}

export function athenaCollectPatientInfo(athenaPatientId: number, practice: number, department: number) {
  const path = `${API_URLS.ATHENAAPI.PATIENT}/${athenaPatientId}`;
	const additionalParams = {
		queryParams: {
			practice: practice,
			department: department
		}
	};
  return requests.get(SERVICES.ATHENAAPI, {}, path, additionalParams);
}