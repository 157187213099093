// @flow
import React from 'react';
import { Box } from '@chakra-ui/react';
import { getFileNameOnly } from '../../common/reports/report-common';
import { getPatientId } from '../filters';
import ColorectalRecommendationSection from '../../colorectal/ColorectalRecommendationSection';
import ColorectalExplanatorySection from '../../colorectal/ColorectalExplanatorySection';
import colorectalImage from '../../images/colorectal-cancer.png';
import withNavigation from '../../main/WithRoutes';
import ColorectalChartsSection from '../../colorectal/ColorectalChartsSection';
import colorectalReportCommon from "../../colorectal/colorectal-report-common";
import GeneticReportOrMobile from "../../common/reports/GeneticReportOrMobile";
import withMobile from "../../common/mobile/withMobile";

type Props = {
  location: any,
  params: any,
  printing: boolean,
  isMobile: boolean
};
type State = {};

export class DemoColorectalRiskReportImpl extends React.Component<
  Props,
  State
> {
  static loadDemoData() {
    // $FlowFixMe[prop-missing]
    const requireContext: any = require.context('./data', true, /\.json$/);
    const json = {};
    requireContext.keys().forEach(key => {
      const obj = requireContext(key);
      const simpleKey = getFileNameOnly(key);
      json[simpleKey] = obj;
    });
    return json;
  }

  constructor(props: Props) {
    super(props);
    this.patientId = getPatientId(props.params, props.location);
    const colorectals = DemoColorectalRiskReportImpl.loadDemoData();
    const key = `colorectal${this.patientId}`;
    this.colorectal =
      colorectals[key] === undefined
        ? colorectals.colorectal1
        : colorectals[key];
  }

  colorectal: Object;
  patientId: number;

  render() {
    const {isMobile} = this.props;
    return (
      <Box>
        <GeneticReportOrMobile
          reportTitle="COLORECTAL CANCER RISK REPORT"
          configTrait="colorectal"
          titleImage={colorectalImage}
          patientNum={this.patientId}
          geneticKitId={`OD000${this.patientId}`}
          printing={this.props.printing}
          geneticRiskInfoText={[
            colorectalReportCommon.GENETIC_RISK_COLORECTAL_INFO_TEXT
          ]}
          riskPercentile={this.colorectal.colorectal_cancer.percentile}
          chartsSection={
            <ColorectalChartsSection
              percentile={this.colorectal.colorectal_cancer.percentile}
              quintile={Math.floor(
                this.colorectal.colorectal_cancer.percentile / 20
              )}
              deciles={this.colorectal.colorectal_cancer.deciles}
              oddsRatios={this.colorectal.colorectal_cancer.odds_list}
              printing={this.props.printing}
            />
          }
          recommendationSection={
            <ColorectalRecommendationSection isMobile={isMobile} />
          }
          explanatorySection={<ColorectalExplanatorySection isMobile={isMobile} />}
        />
      </Box>
    );
  }
}

export default withNavigation(withMobile(DemoColorectalRiskReportImpl));
