import React, {useCallback, useMemo, useState} from 'react';
import {
  Box,
  Text,
  Flex,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel
} from '@chakra-ui/react';
import { v4 as uuid4 } from 'uuid';

import withNavigation from '../main/WithRoutes';
import DemoLeftBarItem from "./DemoLeftBarItem";
import COLORS from "../common/colors";
import {getPatientId} from "./filters";
import {isMaleDemoPatient} from "./clinical";
import {LEFT_BAR_ITEMS} from "./demo-report-items";

type Props = {
  params: any,
  navigate: any,
  location: any,
  onSelectedItemChange: any,
  completed_tests: number,
  selectedGroup: string
};


export function DemoLeftBarImpl(props: Props) {
  const patientId = getPatientId(props.params, props.location);
  const isMale = isMaleDemoPatient(patientId);

  const items = LEFT_BAR_ITEMS;

  const defaultSelection = useMemo(()=> {
    const defaultSelection = {};
    Object.keys(items).forEach((key) => {
      defaultSelection[key] = false;
      Object.keys(items[key].childItems).forEach((childKey) => {
        defaultSelection[childKey] = false;
      });
    });
    return defaultSelection;
  }, [items]);

  const [selection, setSelection] = React.useState(defaultSelection);
  const {onSelectedItemChange} = props;
  const getGroupAndItem = useCallback((token) => {
    if (Object.keys(items).includes(token)) {
      return {
        group: token,
        item: Object.keys(items[token].childItems)[0]
      };
    }
    const groupAndItem= {
      group: undefined,
      item: undefined
    };

    Object.entries(items).forEach(([group, groupItem]) => {
      Object.keys(groupItem.childItems).forEach((childKey) => {
        if (childKey === token) {
          groupAndItem.group = group;
          groupAndItem.item = childKey
        }
      });
    });
    return groupAndItem;
  }, [items]);
  const onItemSelected = useCallback((token) => {
    const updatedSelection = {...defaultSelection}
    updatedSelection[token] = true;
    setSelection(updatedSelection)
    const {group, item} = getGroupAndItem(token);
    onSelectedItemChange(group, item);
  }, [onSelectedItemChange, defaultSelection, getGroupAndItem])
  const [openIndexes, setOpenIndexes] = useState([0, 1, 2, 3]);

  const onAccordionChange =useCallback(      (expandedIndexes, callbackPostChange) => {
      setOpenIndexes(expandedIndexes);
      }, [setOpenIndexes]);

  const female_only_items = useMemo(()=>  ['breast cancer', 'breast'], []);
  const male_only_items = useMemo( () => ['prostate cancer', 'prostate'], []);

  const isDisabledItem = useCallback(itemName => {
    let disabled = false;
    if (isMale && female_only_items.includes(itemName.toLowerCase())) {
      disabled = true;
    } else if (!isMale && male_only_items.includes(itemName.toLowerCase())) {
      disabled = true;
    }
    return disabled;
  }, [isMale, female_only_items, male_only_items]);

  return (
      <Flex minW={"270px"}>
        <Box>
          <Text mt={"30px"} mb="20px" fontWeight={"normal"} fontStyle={"italic"} ml={"30px"} fontSize={18} color={COLORS.REPORT_TEXT_GRAY}>REPORTS</Text>
          <Accordion
            mt={"15px"}
            index={openIndexes}
            onChange={onAccordionChange}
          >
            {Object.entries(items).map(([itemKey, item]) => (
              <AccordionItem
                 key={uuid4()}
              >
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton >
                        <DemoLeftBarItem
                            name={item.name}
                            idToken={itemKey}
                            level={0}
                            onClick={onItemSelected}
                            selected={selection[itemKey]}
                        		color={item.color}
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} pr={"50%"} minW={"100%"}>
                      {Object.entries(item.childItems).map(([childKey, childItem]) => (
                        <Box minW={"200px"} key={uuid4()}>
                          <DemoLeftBarItem
                              name={childItem.name}
                              idToken={childKey}
                              icon={childItem.icon}
                              level={1}
                              onClick={onItemSelected}
                              selected={selection[childKey]}
                              isDisabled={isDisabledItem(childItem.name)}
                              color={childItem.color}
                          />
                        </Box>
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>))}
          </Accordion>
      </Box>
      <Box minH="300vh" minW="1px" maxW="1px" bg={"gray.200"} mt={"1vh"}></Box>
    </Flex>
  );
}

export default withNavigation(DemoLeftBarImpl);
