// @flow
export default function capitalizeFirstLetter(
  s: ?string,
  lowerAllOthers: boolean = true
) {
  if (!s || s.length === 0) return s;
  return (
    s.charAt(0).toUpperCase() +
    (lowerAllOthers ? s.slice(1).toLowerCase() : s.slice(1))
  );
}

export function reverseString(s: string) {
  return s.split('').reverse().join('');
}