// @flow
import React, { useCallback } from 'react';
import {v4 as uuid4} from "uuid";
import {
  Flex,
  Box,
  Text,
  Stack,
  Spacer,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalBody,
  // FormControl,
  // useDisclosure,
  // Alert,
  // AlertIcon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';

import ChangeSlider from '../ChangeSlider';
import LabeledSwitch from '../LabeledSwitch';
import COLORS from '../colors';
import ActionButton from '../ActionButton';
import RelativeRiskGauge from '../RelativeRiskGauge';
import OverallRiskGauge from '../OverallRiskGauge';
import {
  getRiskColorByQuintile,
  getRiskColorByPercentile,
  getRiskLevelByQuintile,
  getRiskLevelByPercentile
} from '../risk-colors';
import { getRelativeRiskLabel } from '../relative-risk';
import CalculatorHeader from '../reports/CalculatorHeaderBar';
import traitRanges from '../reports/trait-ranges.json';
import GaugeChangeDetails from '../GaugeChangeDetails';
import PopoverInfo from '../PopoverInfo';
import InfoButton from '../InfoButton';
import withCalculator from "./WithCalculator";
import LabeledSelection from "../LabeledSelection";
import {getCadRiskPlotBands} from "../risk-gauge-utils";
import CalculatorPatientAttributes from "./CalculatorPatientAttributes";
import type {BooleanAttr, NumericAttr, SelectionAttr} from "./calculator-attr";
import PropTypes from "prop-types";

type Props = {
  disease: string,
  sex: number,
  numericAttrs: Array<NumericAttr>,
  booleanAttrs: Array<BooleanAttr>,
  selectionAttrs: Array<SelectionAttr>,
  onResetAttributes: any,
  getSwitchColor: any,
  getSwitchText: any,
  gender: ?string,
  onGenderSelected: any,
  riskQuintile: number,
  riskPercentile: number,
  playingEnabled: boolean,
  relativeRisk: number,
  relativeRiskOddsRatio: number,
  current: number,
  expected: number,
  shortText: string,
  longText: string,
  summaryChangeLine: string,
  trendIcon: any,
  changeColor: string,
  currentPercentile: number,
  expectedPercentile: number,
  shortTextPercentile: string,
  longTextPercentile: string,
  summaryChangeLinePercentile: string,
  trendIconPercentile: any,
  changeColorPercentile: string,
  currentRisk: number,
  expectedRisk: number,
  shortTextRisk: string,
  longTextRisk: string,
  changeColorRisk: string,
  showRisk: boolean,
  showRelativeToPop: boolean,
  showRelativeToCurrent: boolean,
  riskGauge: Object,
  relativeToPopGauge: Object,
  printing: boolean,
  infoTexts: Object,
  callbackGetColorByRisk: any,
  callbackGetRiskLevel: any,
  callbackGetRecommendations: any,
  callbackOnSavePatientAttrs: any,
  callbackOnCancelEditPatientAttr: any,
  callbackGetGaugeHeader: any,
  callbackTranslateOverallRiskValue: any,
  allowPatientAttrsEditing: boolean,
  allowPatientAttrsFirstMissingEditing: boolean,
  relativeToPopTitle?: string
};

function CalculatorImpl(props: Props) {
  const longLines = props.longText ? props.longText.split('\n') : [''];
  const longLinesPercentile = props.longTextPercentile
    ? props.longTextPercentile.split('\n')
    : [''];

  const {callbackTranslateOverallRiskValue} = props;
  const getOverallRiskDiffLabel = useCallback((current, expected, fixDigits) => {
    const diff = expected - current;
    let value = expected;
    if (diff === 0)
      value = current;
    if (callbackTranslateOverallRiskValue) value = callbackTranslateOverallRiskValue(value);
    return `${value.toFixed(fixDigits)}%`;
  }, [callbackTranslateOverallRiskValue]);

  const getRiskLabel = useCallback((current, expected, fixDigits, highLimit) => {
    if (expected > highLimit) return `> ${highLimit}%`;
    return `${expected.toFixed(fixDigits)}%`;
  }, []);


  const populationTabIndex = props.showRisk ? 1 : 0;
  let currentTabIndex = 2;
  if (!props.showRisk) currentTabIndex -= 1;
  if (!props.showRelativeToPop) currentTabIndex -= 1;

  const [tabIndex, setTabIndex] = React.useState(0);
  const onRiskLevelClick = useCallback(() => setTabIndex(0), [setTabIndex]);
  const onPopulationClick = useCallback(() => setTabIndex(populationTabIndex), [setTabIndex, populationTabIndex]);
  const onCurrentClick = useCallback(() => setTabIndex(currentTabIndex), [setTabIndex, currentTabIndex]);
  const handleTabsChange = useCallback(index => {
    setTabIndex(index)}, [setTabIndex]
  );

  const leftSizeWidth = props.printing ? "30%" : "40%";

  function getOutsideAgeRange() {
    let isYoungerThanMin = false;
    let isOlderThanMax = false;
    for (let i = 0; i < props.numericAttrs.length; i += 1) {
      if (props.numericAttrs[i].name === "age") {
        isYoungerThanMin = props.numericAttrs[i].currentValue && props.numericAttrs[i].currentValue < traitRanges.age.lowLimit;
        isOlderThanMax = props.numericAttrs[i].currentValue && props.numericAttrs[i].currentValue > traitRanges.age.highLimit;
      }
    }
    return {isYoungerThanMin, isOlderThanMax};
  }

  let {isYoungerThanMin, isOlderThanMax} = getOutsideAgeRange();
  const headerText = `Change risk factor values to check the effect on your relative risk to develop ${props.disease}`
  const recommendations = props.callbackGetRecommendations ? props.callbackGetRecommendations(
    props.currentPercentile,
    props.currentRisk
  ) : null;
  const gaugeHeader = props.callbackGetGaugeHeader ? props.callbackGetGaugeHeader(
    props.expectedPercentile,
    props.expectedRisk,
    tabIndex
  ) : null;

  const getIsSliderEnabled = useCallback((attr: Object) => {
    if (!attr.isAdjustable || attr.currentValue === null || attr.currentValue === undefined) return false;
    if (attr.isSliderEnabled.constructor === Array) return attr.isSliderEnabled[tabIndex];
    return attr.isSliderEnabled;
  }, [tabIndex]);

  return (
    <Stack borderWidth={1} borderColor="gray.200" color={COLORS.REPORT_TEXT}>
      <CalculatorHeader />
      <Flex mt="10px" minW="100%">
        <Flex borderColor="gray.100" borderWidth={1} flex="1" mx="1%" w="55%">
          <Box minW={leftSizeWidth} w={leftSizeWidth} ml="3%" mr="2%" my="20px" fontSize={13}>
            <Stack spacing={props.printing ? "5px" : "15px"}>
              {!props.printing && <Text fontSize={13}>
                <i>
                  {headerText}
                </i>
              </Text>}
              {props.numericAttrs.map(attr=>
                attr.isAdjustable ?
                  <ChangeSlider
                    val={props[attr.name]}
                    minVal={attr.min}
                    maxVal={attr.max}
                    stepVal={attr.step}
                    resetVal={attr.resetValue}
                    leftLabel={attr.displayText}
                    callbackGetColor={attr.fGetColorByValue}
                    callbackRightLabel={attr.fGetDiffText}
                    callbackOnValueChange={attr.onChange}
                    callbackOnValueEndChange={attr.onChange}
                    enabled={getIsSliderEnabled(attr)}
                    attrName={attr.name}
                    popoverInfo={attr.name === 'age' && (isYoungerThanMin || isOlderThanMax) ? props.infoTexts.agePopInfoTexts : undefined}
                  /> : null)
              }
              {props.booleanAttrs.map(attr=>
                attr.visible ? <Box key={uuid4()}>
                  <LabeledSwitch
                    isChecked={props[attr.name]}
                    leftLabel={attr.displayText}
                    leftLabelWidth="200px"
                    callbackGetColor={attr.callbackGetColor ? attr.callbackGetColor : props.getSwitchColor}
                    callbackOnValueChange={attr.onChange}
                    callbackRightLabel={props.getSwitchText}
                    enabled={props.playingEnabled}
                    attrName={attr.name}
                    popoverInfo={attr.infoTexts}
                  />
                </Box> : null)
              }
              {props.selectionAttrs.map(attr=>
                <Box mt="10px" key={uuid4()}>
                  <LabeledSelection
                    leftLabelWidth="200px"
                    currentValue={props[attr.name]}
                    keyNameOptions={attr.keyNameOptions}
                    leftLabel={attr.displayText}
                    optionWidth={"150px"}
                    callbackOnValueChange={attr.onChange}
                    enabled={props.playingEnabled}
                    attrName={attr.name}
                  />
                </Box>)
              }
              <ActionButton
                fontSize={14}
                onClick={props.onResetAttributes}
                borderWidth={1}
                borderColor={COLORS.REPORT_TEXT}
                name="Reset"
                color={COLORS.REPORT_TEXT}
              />
            </Stack>
          </Box>
          <Tabs
            variant="soft-rounded"
            my="10px"
            size="lg"
            index={tabIndex}
            mb="20px"
            spacing="20px"
            minW="50%"
            onChange={handleTabsChange}
          >
            <TabList>
              {props.showRisk ? <Box>
                <Tab w={"200px"}>{props.riskGauge.title}</Tab>
                <PopoverInfo
                  trigger={
                    <Box>
                      <InfoButton
                        onClick={onRiskLevelClick}
                        mt="-20px"
                        minW="100px"
                        minH="20px"
                      />
                    </Box>
                  }
                  header={props.infoTexts.riskLevel.header}
                  text={props.infoTexts.riskLevel.text}
                  footer={props.infoTexts.riskLevel.footer}
                />
              </Box> : null}
              {props.showRelativeToPop ? <Box>
                <Tab>{props.relativeToPopTitle}</Tab>
                <PopoverInfo
                  trigger={
                    <Box>
                      <InfoButton
                        onClick={onPopulationClick}
                        mt="-20px"
                        minW="100px"
                        minH="20px"
                      />
                    </Box>
                  }
                  header={props.infoTexts.relativeToPopulation.header}
                  text={props.infoTexts.relativeToPopulation.text}
                  footer={props.infoTexts.relativeToPopulation.footer}
                />
              </Box> : null}
              {props.showRelativeToCurrent ? <Box>
                <Tab>Relative to Current</Tab>
                <PopoverInfo
                  trigger={
                    <Box>
                      <InfoButton
                        onClick={onCurrentClick}
                        mt="-20px"
                        minW="100px"
                        minH="20px"
                      />
                    </Box>
                  }
                  header={props.infoTexts.relativeToCurrent.header}
                  text={props.infoTexts.relativeToCurrent.text}
                  footer={props.infoTexts.relativeToCurrent.footer}
                />
              </Box> : null}
              <Spacer/>
            </TabList>
            <TabPanels>
              {props.showRisk && <TabPanel>
                {gaugeHeader}
                <OverallRiskGauge
                  callbackGetColor={props.callbackGetColorByRisk}
                  callbackGetRiskLevel={props.callbackGetRiskLevel}
                  callbackGetOverallRiskLabel={getRiskLabel}
                  callbackGetPlotBands={getCadRiskPlotBands}
                  currentPercentile={props.currentRisk}
                  expectedPercentile={props.expectedRisk}
                  showSubtitle={false}
                  fixDigits={1}
                  overallRiskTitle={props.riskGauge.title}
                  callbackGetOuterPlotBands={props.riskGauge.callbackGetOuterPlotBands}
                  lines={props.riskGauge.lines}
                />
                {props.currentRisk !== props.expectedRisk && (
                  <GaugeChangeDetails
                    headerLine="Estimated risk change"
                    changeColor={props.changeColorRisk}
                    changeLines={recommendations ? [] : longLinesPercentile}
                    trendIcon={props.trendIconPercentile}
                    shortText={props.shortTextRisk}
                    summaryLine={recommendations ? null : props.summaryChangeLinePercentile}
                    printing={props.printing}
                  />
                )}
                {recommendations}
              </TabPanel>}
              {props.showRelativeToPop && <TabPanel>
                {gaugeHeader}
                <OverallRiskGauge
                  callbackGetColor={getRiskColorByPercentile}
                  callbackGetRiskLevel={getRiskLevelByPercentile}
                  callbackGetOverallRiskLabel={getOverallRiskDiffLabel}
                  currentPercentile={props.currentPercentile}
                  expectedPercentile={props.expectedPercentile}
                  showSubtitle={false}
                  overallRiskTitle={props.relativeToPopGauge ? props.relativeToPopGauge.title : 'RISK PERCENTILE'}
                />
                {props.currentPercentile !== props.expectedPercentile && (
                  <GaugeChangeDetails
                    headerLine={props.relativeToPopGauge ? props.relativeToPopGauge.changeLabel : "Estimated risk percentile change"}
                    changeColor={props.changeColorPercentile}
                    changeLines={recommendations ? [] : longLinesPercentile}
                    trendIcon={props.trendIconPercentile}
                    shortText={props.shortTextPercentile}
                    summaryLine={recommendations ? null : props.summaryChangeLinePercentile}
                    printing={props.printing}
                  />
                )}
                {recommendations}
              </TabPanel>}
              {props.showRelativeToCurrent && <TabPanel>
                <RelativeRiskGauge
                  percentile={props.riskPercentile}
                  quantile={props.riskQuintile}
                  callbackGetColor={getRiskColorByQuintile}
                  callbackGetRiskLevel={getRiskLevelByQuintile}
                  callbackGetRelativeRiskLabel={getRelativeRiskLabel}
                  relativeRisk={props.relativeRisk}
                  relativeRiskOddsRatio={props.relativeRiskOddsRatio}
                  showSubtitle={false}
                />
                {props.current !== props.expected && (
                  <GaugeChangeDetails
                    headerLine="Estimated relative risk change"
                    changeColor={props.changeColor}
                    changeLines={recommendations ? [] : longLines}
                    trendIcon={props.trendIcon}
                    shortText={props.shortText}
                    summaryLine={recommendations ? null :  props.summaryChangeLine}
                    printing={props.printing}
                  />
                )}
                {recommendations}
              </TabPanel>}
            </TabPanels>
          </Tabs>
        </Flex>
        {!props.printing && (
          <Box borderColor="gray.100" borderWidth={1} mr="1%" w="25%" minW="25%">
            <CalculatorPatientAttributes
              title={"Patient attributes"}
              sex={props.sex === 1}
              geneticRiskTrait={props.disease}
              geneticRiskLevel={getRiskLevelByPercentile(props.riskPercentile).toLowerCase()}
              numericAttrs={props.numericAttrs}
              booleanAttrs={props.booleanAttrs}
              selectionAttrs={props.selectionAttrs}
              labelPrefix=""
              showBorder={false}
              spacing={"0px"}
              callbackOnSave={props.callbackOnSavePatientAttrs}
              callbackOnCancelEdit={props.callbackOnCancelEditPatientAttr}
              allowEditing={props.allowPatientAttrsEditing}
              allowFirstMissingEditing={props.allowPatientAttrsFirstMissingEditing}
            />
          </Box>)
        }
      </Flex>
      <Flex mb="10px" minW="100%">
        <Box mx="20px" mb="20px">
          <Text fontSize={13}>
            <i>
              {props.infoTexts.model}
            </i>
          </Text>
          {isYoungerThanMin && props.infoTexts.youngerParticipant && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {props.infoTexts.youngerParticipant}
            </i>
          </Text>)}
          {isOlderThanMax && props.infoTexts.olderParticipant && (<Text mt="10px" color={COLORS.RED_STATUS} fontSize={13} fontWeight={"bold"}>
            <sup>*</sup>
            <i>
              {props.infoTexts.olderParticipant}
            </i>
          </Text>)}
        </Box>
      </Flex>
    </Stack>
  );
}

export default withCalculator(CalculatorImpl);


CalculatorImpl.propTypes = {
  relativeToPopTitle: PropTypes.string,
  callbackGetGaugeHeader: PropTypes.any,
  callbackTranslateOverallRiskValue: PropTypes.any
};

CalculatorImpl.defaultProps = {
  relativeToPopTitle: "Relative to Population",
  callbackGetGaugeHeader: undefined,
  callbackTranslateOverallRiskValue: undefined
}
