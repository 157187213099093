// @flow
import React, { useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import { Box, IconButton } from '@chakra-ui/react';
import { ImPrinter } from 'react-icons/im';

type Props = {
  componentToPrint: any,
  callbackOnBeforeGetContent: any,
  callbackOnAfterPrint: any
};

const PrintButton = (props: Props) => {
  const { componentToPrint } = props;
  const getPrintButtonContent = () => (
    <Box as={ImPrinter} h="18px" w="18px" mb={0} />
  );
  const trigger = useCallback(() => (
    <IconButton
      icon={getPrintButtonContent()}
      bg="transparent"
      aria-label="print"
      title="print"
    />
  ), []);

  const getContent = useCallback(() => componentToPrint.current, [componentToPrint]);
  return (
    <>
      <ReactToPrint trigger={trigger} content={getContent} onBeforeGetContent={props.callbackOnBeforeGetContent} onAfterPrint={props.callbackOnAfterPrint}/>
    </>
  );
};

export default PrintButton;
